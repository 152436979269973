import { transform, isEqual, isObject } from 'lodash'

export const ObjectUtils = {
  // eslint-disable-next-line
  mergeObjectLeft: (obj1: Record<string, any>, obj2: Record<string, any>) => {
    for (const key of Object.keys(obj1)) {
      obj1[key] = obj2[key] ?? obj1[key]
    }

    return obj1
  },

  differenceProperties: <T>(object, base) => {
    return transform(object, (result: T, value, key) => {
      if (!isEqual(value, base[key])) {
        // console.log('Difference detected at path:', value)
        result[key] =
          isObject(value) && isObject(base[key]) ? ObjectUtils.differenceProperties(value, base[key]) : value
      }
    })
  },
  isDifference: (object, base) => {
    return !!Object.keys(ObjectUtils.differenceProperties(object, base)).length
  },
}
